import { Routes } from '@angular/router';
import { ActiveSessionsComponent } from './active-sessions/active-sessions.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AppWrapperComponent } from './app-wrapper/app-wrapper.component';
import { authGuard } from './core/guard/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { OtpComponent } from './otp-auth/otp.component';
import { UserFormComponent } from './user-form/user-form.component';
import { UserManagementComponent } from './user-management/user-management.component';


export const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'otp', component: OtpComponent },
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'app', component: AppWrapperComponent, 
        canActivateChild: [authGuard],
        children: [
        { path: '', redirectTo: '/admin/users', pathMatch: 'full' },
        { path: 'dashboard', component: AdminDashboardComponent },
        { path: 'admin/users', component: UserManagementComponent },
        { path: 'admin/users/create', component: UserFormComponent },
        { path: 'admin/users/edit/:id', component: UserFormComponent },
        { path: 'admin/sessions', component: ActiveSessionsComponent },
        { path: 'forgot-password', component: ForgotPasswordComponent }
        ]
    },
    { path: '**', redirectTo: '/login'},
];

