import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoaderService {
  private spinnerCounter = 0;
  private  isLoading = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoading.asObservable()

  constructor() { }

  show() {
    this.spinnerCounter++;
    this.isLoading.next(true);
  }

  hide() {
    this.spinnerCounter--;
    if (this.spinnerCounter <= 0) {
      this.spinnerCounter = 0;
      this.isLoading.next(false);
    }
   
  }

  isLoadingStatus() {
    return this.isLoading;
  }
}
