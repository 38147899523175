import { CommonModule, NgFor } from '@angular/common';
import { Component, OnInit, computed, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IUser, IUsers } from '@models/user.model';
import { UserService } from '@services/user.service';
import { searchDataByKeys } from '@utils/searchDataByKeys';
import { NavigateService } from '../core/services/navigate.service';

@Component({
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
  standalone: true,
  imports: [FormsModule, NgFor, CommonModule],
})

export class UserManagementComponent implements OnInit {
  userData = signal<IUsers>([]);
  filterCriteria = signal('');    // Signal for filter criteria from input
  isFormOpen = false; // To control the modal visibility
  isEditing = false; // To track if editing or creating a new user
  userForm: IUser = {
    id: 0,
    userName: '',
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    active: false,
    locked: false,
    unsuccessfulAttempts: 0,
    externalProperties: '',
    createdDate: '',
    modifiedDate: '',
    password: '',
    salt: '',
    sessions: []
  }; // Form data
  currentUserIndex: number | null = null; // Track index for editing
  filteredUsers = computed(() => {
    const search = this.filterCriteria().toLowerCase();
    return searchDataByKeys(this.userData(), search, ['userName', 'email'])
  });
  constructor(private userService: UserService, private navigateService: NavigateService) { }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(): void {
    this.userService.getUsers().subscribe({
      next: (users) => {
        this.userData.set(users)
      }
    })
  }

  // Search functionality
  onFilter(event: Event) {
    const input = (event.target as HTMLInputElement).value;
    this.filterCriteria.set(input);
  }

  // Open form for creating a new user
  openUserForm() {
    this.isFormOpen = true;
    this.isEditing = false; // Reset editing state
    this.userForm = {
      id: 0,
      userName: '',
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      active: false,
      locked: false,
      unsuccessfulAttempts: 0,
      externalProperties: '',
      createdDate: '',
      modifiedDate: '',
      password: '',
      salt: '',
      sessions: []
    };
    document.body.classList.add('blur-background'); // Apply blur to background
  }

  // Open form for editing a user
  editUser(user: any) {
    this.isFormOpen = true;
    this.isEditing = true;
    this.userForm = { ...user }; // Populate form with user data
    document.body.classList.add('blur-background'); // Apply blur to background
    this.navigateService.toUserEditForm(this.userForm.id);

  }

  // Submit form (Create or Edit)
  submitForm() {
    this.userService.updateUser(this.userForm).subscribe((response) => {
      this.userData.set(this.userData().filter(user => user.id !== this.userForm.id));
      this.userData.set([...this.userData(), this.userForm]);
    });
    this.closeForm(); // Close the form after submission
  }

  // Close the form
  closeForm() {
    this.isFormOpen = false;
    this.currentUserIndex = null; // Reset index
    document.body.classList.remove('blur-background'); // Remove blur from background
  }

  // Delete user
  deleteUser(user: IUser) {
    const userId = user.id;
    this.userData.update((users) => users.filter(user => user.id !== userId));
  }
}