import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { NavigateService } from '@services/navigate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  styleUrls: ['./header.component.css'],
  imports: [CommonModule]
})
export class HeaderComponent implements OnInit {
  isDropdownVisible = false;

  // Function to toggle the dropdown
  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }
  constructor(private navigateService: NavigateService, private authService: AuthService) { }

  ngOnInit() {
  }

  logout() {
    this.authService.singOut().subscribe({next: (response) =>{
      this.navigateService.toLogin();
    }, error:(err) => {
      console.log(err);
      this.navigateService.toLogin();
    }})
    
  }

  userManagement(){
   this.navigateService.toUserManagement();
  }

  activeSession(){
    this.navigateService.toActiveSession()
  }

}
