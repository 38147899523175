import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APP_CONFIG } from '@core/config/appConfig';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { LocalStoreService } from './local-store.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

constructor(private http: HttpClient, private localStore: LocalStoreService) { }

  signIn(username: string, password: string): Observable<string>{
    const path = APP_CONFIG.baseUrl + 'admin/login';
    const authHeader = `Basic ${btoa(`${username}:${password}`)}`;
    // Set headers with the authorization
    const headers = new HttpHeaders({
      'Authorization': authHeader,
    });
    // Make the API call with Basic Authentication
    return this.http.post(path,{}, { headers, responseType: 'text' })
    .pipe(
      tap(token => {
        this.localStore.setToken(token);
        this.localStore.setSession(token);
      })
    );
  }

  singOut(): Observable<any>{
    const path = APP_CONFIG.baseUrl + 'admin/logout';
    const sessionId = this.localStore.getSession()
    const headers = new HttpHeaders({
      'SessionId': sessionId,
    });
    return this.http.post(path,{}, {headers, responseType: 'text' }).pipe( 
      tap(response => {
        this.localStore.logOut();
      }),
      catchError(error =>{
        this.localStore.logOut();
        throw error;
      })
    );
  }

  isLogin(){
   return this.localStore.isLogin()
  }

}
