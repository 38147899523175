<section class="usa-section">
  <div class="modal">
    <div class="modal-content">
      <h2>{{ userId ? 'Edit User' : 'Create User' }}</h2>
      <form (ngSubmit)="onSubmit()">
        <div class="form-group" *ngIf="userId">
          <label for="username">Username:</label>
          <input type="text" id="username" class="usa-input" [(ngModel)]="userForm.userName" name="username" required />
        </div>
        <div class="form-group">
          <label for="username">First Name:</label>
          <input type="text" id="firstName" class="usa-input" [(ngModel)]="userForm.firstName" name="firstName"
            required />
        </div>
        <div class="form-group">
          <label for="username">Last Name:</label>
          <input type="text" id="lastName" class="usa-input" [(ngModel)]="userForm.lastName" name="lastName" required />
        </div>
        <div class="form-group">
          <label for="username">Middle Name:</label>
          <input type="text" id="lastName" class="usa-input" [(ngModel)]="userForm.middleName" name="middleName"
            required />
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" class="usa-input" [(ngModel)]="userForm.email" name="email" required />
        </div>
        <div class="form-group">
          <label> Active</label>

          <input type="checkbox" [checked]="userForm.active" [(ngModel)]="userForm.active" name="active" id="active" />
        </div>
        <div class="form-group">
          <label> Locked</label>

          <input type="checkbox" [checked]="userForm.locked" [(ngModel)]="userForm.locked" name="locked" id="locked" />
        </div>
        <button type="submit" class="usa-button">{{ userId ? 'Update User' : 'Create User' }}</button>
        <button type="button" class="usa-button usa-button--secondary" (click)="closeForm()">Cancel</button>
        <button type="button" class="usa-button usa-button--primary">Reset Password</button>
      </form>
    </div>
  </div>
</section>