import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigateService {


  constructor(private router: Router, private location: Location) { }

  toApp(): void {
    this.router.navigate(['/app/admin/users']);
  }
  toLogin(): void {
    this.router.navigate(['/login']);
  }
  toForgetPassword(): void {
    this.router.navigate(['/forgot-password']);
  }
  toUserManagement(): void {
    this.router.navigate(['/app/admin/users']);
  }
  toActiveSession() {
    this.router.navigate(['/app/admin/sessions'])
  }
  toUserEditForm(id: number) {
    this.router.navigate([`app/admin/users/edit/${id}`]);
  }
}