import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  standalone: true,
  imports: [FormsModule,CommonModule,ReactiveFormsModule]
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  constructor(private fb: FormBuilder,public router: Router) {
    this.forgotPasswordForm = this.fb.group({
        email: ['', [Validators.required, Validators.email]]
      });
   }

   ngOnInit(): void {}

  onSubmit() {
    if (this.forgotPasswordForm.valid) {
      // Send the form data to backend to initiate password reset
      const email = this.forgotPasswordForm.value.email;
      console.log('Forgot password email:', email);
      this.router.navigate(['/login']);
      // Add API call to send the email
    }
  }
}
