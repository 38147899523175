<section class="usa-section">
  <div class="grid-container">
    <h1 class="usa-heading">Active Sessions</h1>

    <!-- Filter Inputs in a Grid -->
    <div class="filters-grid">
      <input type="text" class="usa-input" placeholder="Filter by user or status..." (input)="filterSessions($event)" />
      <select class="usa-select" (change)="filterByStatus($event)">
        <option value="all">All Sessions</option>
        <option value="active">Active Sessions</option>
        <option value="inactive">Inactive Sessions</option>
      </select>
    </div>

    <!-- Active Sessions Table Grid -->
    <div class="table-grid">
      <table class="usa-table usa-table--borderless usa-table--striped full-width-table">
        <thead>
          <tr>
            <th>User</th>
            <th>Session Start</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let session of filteredSessions">
            <td>{{ session.user }}</td>
            <td>{{ session.startTime }}</td>
            <td [ngClass]="{'status-active': session.status === 'active', 'status-inactive': session.status === 'inactive'}">
              {{ session.status }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
