import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { IUser } from '../core/models/user.model';
import { NavigateService } from '../core/services/navigate.service';
import { UserService } from '../core/services/user.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.css'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, CommonModule],
})
export class UserFormComponent implements OnInit {
  // userForm: FormGroup;
  isEditMode = false;
  userId: any;
  userForm: IUser = {
    id: 0,
    userName: '',
    firstName: '',
    lastName: '',
    middleName: '',
    email: '',
    active: false,
    locked: false,
    unsuccessfulAttempts: 0,
    externalProperties: '',
    createdDate: '',
    modifiedDate: '',
    password: '',
    salt: '',
    sessions: []
  };
  constructor(private activatedRoute: ActivatedRoute, private userService: UserService, private navigateService: NavigateService) {

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.userId = params['id'];
    });
    this.getUser();
  }
  getUser() {
    this.userService.getUserById(this.userId).subscribe((response) => {
      this.userForm = { ...response };
    });
  }

  onSubmit() {
    this.userService.updateUser(this.userForm).subscribe((response) => {
      console.log(response);
    });
  }
  closeForm() {
    this.navigateService.toApp();
  }
}
