import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../config/appConfig';
import { IUser, IUsers } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  getUserById(userId: any) {
    const path = APP_CONFIG.baseUrl + 'users/' + userId;
    return this.http.get<IUser>(path);
  }

  constructor(private http: HttpClient) { }

  getUsers(): Observable<IUsers> {
    const path = APP_CONFIG.baseUrl + 'users';
    return this.http.get<IUsers>(path);
  }
  updateUser(userForm: IUser) {
    const path = APP_CONFIG.baseUrl + 'users/' + userForm.id;
    return this.http.post(path, userForm);
  }
}
