<section class="usa-section">
  <div class="grid-container">
    <h1 class="usa-heading">User List</h1>

    <!-- Grid for Filter and Create Button -->
    <div class="filter-grid">
      <input type="text" class="usa-input" placeholder="Search users..." (input)="onFilter($event)" />
      <button class="usa-button" (click)="openUserForm()">Create User</button>
    </div>

    <!-- Grid for User Table -->
    <div class="table-grid">
      <table class="usa-table full-width-table">
        <thead>
          <tr>
            <th>Username</th>
            <td>First Name</td>
            <td>Last Name</td>
            <td>Middle Name</td>
            <th>Email</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of filteredUsers()">
            <td>{{ user.userName }}</td>
            <td>{{ user.firstName }}</td>
            <td>{{ user.lastName }}</td>
            <td>{{ user.middleName }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.active ? 'Yes' : 'No' }}</td>
            <td>
              <button class="usa-button usa-button--secondary" (click)="editUser(user)">Edit</button>
              <button class="usa-button usa-button--danger" (click)="deleteUser(user)">Delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</section>