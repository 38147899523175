import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../core/services/auth.service';
import { NavigateService } from '../core/services/navigate.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  standalone: true,
  imports:[FormsModule, CommonModule, HttpClientModule]
})
export class LoginComponent {
  errorMessage: string = '';
  loginData = {
    username: '',
    password: '',
    rememberMe: false
  };


  constructor(private authService: AuthService, private navigateService: NavigateService) {}

  ngOnInit(): void {
    // Check if the user is already logged in
    const isLogin = this.authService.isLogin();
    if (isLogin) {
      this.navigateService.toApp();
    }
  }

  onSubmit() {
    const {username, password} = this.loginData;
    if(!username || !password) return;
    this.errorMessage = '';
    this.authService.signIn(username, password).subscribe({ next: (response) =>{
      this.navigateService.toApp();
    }, error:(error) =>{
      this.errorMessage = error?.error
      console.log(error)
    }})
  }

   // Function to handle Forgot Password click
   onForgotPassword() {
    console.log('Forgot Password clicked');
    // Navigate to Forgot Password page if implemented
    this.navigateService.toForgetPassword();
  }
}

