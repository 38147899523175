<div class="page-wrapper">
    <div class="form-wrapper">
      <!-- Logo Section -->
      <div class="logo">
        <img src="assets/logo.png" alt="Company Logo" class="logo-img">
      </div>
      
      <!-- Login Form -->
      <h1 style="font-size: 2rem; font-weight: bold; color: #111; text-align: center;">AMS Login</h1>
      <form (ngSubmit)="onSubmit()" class="usa-form" #loginForm="ngForm">
        <div class="usa-form-group">
          
          <div class="usa-alert usa-alert--error alert-info" role="alert" *ngIf="errorMessage">
            <div class="usa-alert__body">
              <p class="usa-alert__text">
               {{errorMessage}}
              </p>
            </div>
          </div>

          <input
            type="text"
            id="username"
            class="usa-input"
            name="username"
            placeholder="User Name"
            [(ngModel)]="loginData.username"
            required
            #username="ngModel"
          />
          <div *ngIf="username.invalid && username.touched" class="usa-error-message">Username is required</div>
        </div>
  
        <div class="usa-form-group">
          <input
            type="password"
            id="password"
            class="usa-input"
            name="password"
            placeholder="Password"
            [(ngModel)]="loginData.password"
            required
            #password="ngModel"
          />
          <div *ngIf="password.invalid && password.touched" class="usa-error-message">Password is required</div>
        </div>
  
        <div class="usa-checkbox" style="text-align: left;">
          <input
            class="usa-checkbox__input"
            id="remember-me"
            type="checkbox"
            [(ngModel)]="loginData.rememberMe"
            name="rememberMe"
          />
          <label class="usa-checkbox__label" for="remember-me">Remember my username</label>
        </div>
  
        <button type="submit" class="usa-button" [disabled]="loginForm.invalid">Log In</button>
  
        <!-- Forgot Password Link -->
        <div class="forgot-password">
          <a (click)="onForgotPassword()">Forgot Password?</a>
        </div>
      </form>
  
      <!-- Footer Section -->
      <div class="footer">
        <p>&copy; 2024 Your Company. All rights reserved.</p>
      </div>
    </div>
  </div>
  