<div class="forgot-password-container">
    <img src="assets/logo.png" alt="Company Logo" class="logo" /> <!-- Use your company logo -->
    <h2>Forgot Password</h2>
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <input id="email" type="email" formControlName="email" placeholder="Enter your email" />
        <div *ngIf="forgotPasswordForm.get('email')?.invalid && forgotPasswordForm.get('email')?.touched">
          <small class="error">Valid email is required</small>
        </div>
      </div>
  
      <button type="submit" [disabled]="forgotPasswordForm.invalid">Submit</button>
    </form>
  
    <div class="back-to-login">
      <a (click)="router.navigate(['/login'])">Back to Login</a>
    </div>
  </div>
  