export function searchDataByKeys<T>(dataList: T[], searchText: string, searchFields: (keyof T)[]): T[] {
  if (!searchText) {
    return dataList; // Return the full list if there's no search text
  }
  
  const lowerSearchText = searchText.toLowerCase();
  
  return dataList.filter(item =>
    searchFields.some(field => {
      const value = item[field];
      return typeof value === 'string' && value.toLowerCase().includes(lowerSearchText);
    })
  );
}