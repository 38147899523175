import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class AdminDashboardComponent {
  constructor() {}


 
}
