import { CommonModule } from '@angular/common';
import { Component, OnInit, signal } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '@components/header/header.component';
import { LoaderService } from '@core/services/loader.service';
import { AppSpinnerComponent } from '../components/app-spinner/app-spinner.component';

@Component({
  templateUrl: './app-wrapper.component.html',
  styleUrls: ['./app-wrapper.component.css'],
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, AppSpinnerComponent]
})
export class AppWrapperComponent implements OnInit {
  loading = signal(false);
  constructor(private loaderService: LoaderService){}
  ngOnInit(): void {
   
    this.loaderService.isLoading$.subscribe(value =>{
      this.loading.update((pre) => value)
    })
  }

}
