<div class="page-wrapper">
    <div class="form-wrapper">
      <!-- Logo Section (Optional, if you want a logo here as well) -->
      <div class="logo">
        <img src="assets/logo.png" alt="Company Logo" class="logo-img">
      </div>
  
      <!-- OTP Form -->
      <h1 style="font-size: 2rem; font-weight: bold; color: #111; text-align: center;">OTP Validation</h1>
      <form [formGroup]="otpForm" (ngSubmit)="verifyOTP()" class="usa-form">
        <div class="usa-form-group">
          <input
            class="usa-input"
            id="otpCode"
            formControlName="otpCode"
            placeholder="Enter the OTP Code"
            required
            style="padding: 10px; width: 100%; font-size: 1rem;"
          />
        </div>
  
        <button type="submit" class="usa-button" style="width: 100%; background-color: #0071bc; color: white;">Verify OTP</button>
      </form>
      
      <!-- Footer Section -->
      <div class="footer">
        <p>&copy; 2024 Your Company. All rights reserved.</p>
      </div>
    </div>
  </div>
  