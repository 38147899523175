import { Component, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { SessionService } from './session.service';

@Component({
  selector: 'app-active-sessions',
  templateUrl: './active-sessions.component.html',
  styleUrls: ['./active-sessions.component.css'],
  standalone:true,
  imports:[CommonModule],
  schemas: [NO_ERRORS_SCHEMA]
})

export class ActiveSessionsComponent {
  sessions = [
    { user: 'john.doe', startTime: '2024-09-20 10:00:00', status: 'active' },
    { user: 'jane.doe', startTime: '2024-09-20 10:30:00', status: 'inactive' },
    { user: 'michael.smith', startTime: '2024-09-20 11:00:00', status: 'active' },
    { user: 'sarah.jones', startTime: '2024-09-20 11:15:00', status: 'inactive' },
    // Add more sample sessions here
  ];

  filteredSessions = [...this.sessions]; // For filtering sessions

  // Filter sessions by username or status (active/inactive)
  filterSessions(event: any) {
    const query = event.target.value.toLowerCase();
    this.filteredSessions = this.sessions.filter(
      session =>
        session.user.toLowerCase().includes(query) ||
        session.status.toLowerCase().includes(query)
    );
  }

  // Filter by active/inactive/all status
  filterByStatus(event: any) {
    const status = event.target.value;
    if (status === 'all') {
      this.filteredSessions = [...this.sessions];
    } else {
      this.filteredSessions = this.sessions.filter(session => session.status === status);
    }
  }
}

