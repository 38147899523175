
  <header class="usa-header usa-header--basic">
    <div class="usa-nav-container">
      <div class="usa-navbar">
        <div class="usa-logo" id="basic-logo">
          <em class="usa-logo__text">
            <a title="Home" aria-label="Home" class="logo-items">
              <img src="assets/logo.png" alt="Logo" class="logo-img">
              <span class="logo-span">User AMS</span>
            </a>
          </em>
        </div>
      </div>
      <nav class="usa-nav">
        <ul class="usa-nav__primary usa-accordion">
          <li class="usa-nav__primary-item">
            <a class="usa-nav__link">Federation</a>
          </li>
          <li class="usa-nav__primary-item">
            <a class="usa-nav__link">Configuration</a>
          </li>
          <li class="usa-nav__primary-item">
            <a class="usa-nav__link" (click)="userManagement()">Users</a>
          </li>
          <li class="usa-nav__primary-item">
            <a class="usa-nav__link" (click)="activeSession()">Active Sessions</a>
          </li>
        </ul>
  
        <div class="profile-section">
          <button class="usa-button usa-button--unstyled" id="profile-button" aria-controls="profile-dropdown" aria-expanded="false" aria-haspopup="true" (click)="toggleDropdown()">
            <img src="assets/profile.png" alt="Profile Icon" class="profile-icon">
            <span class="usa-button__text">John Doe</span>
          </button>
        
          <ul id="profile-dropdown" class="usa-nav__submenu" *ngIf="isDropdownVisible">
            <li><a><i class="fas fa-user"></i> Profile</a></li>
            <li><a><i class="fas fa-cog"></i> Settings</a></li>
            <li (click)="logout()"><a><i class="fas fa-sign-out-alt"></i> Logout</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
 