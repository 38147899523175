import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStoreService } from '../services/local-store.service';

export const authGuard = () => {
  const router = inject(Router);
  const localStoreService = inject(LocalStoreService);
  const isLogin = localStoreService.isLogin();

  if (isLogin) {
    return true;
  } else {
    router.navigate(['/login']);
    localStoreService.logOut()
    return false;
  }
};