import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css'],
  standalone: true,
  imports: [RouterOutlet,ReactiveFormsModule],
})
export class OtpComponent implements OnInit {
  otpForm: FormGroup;

  constructor(private fb: FormBuilder, private router: Router) {
    this.otpForm = this.fb.group({
      otpCode: ['', Validators.required],
    });
  }

  ngOnInit(): void {}

  verifyOTP() {
    if (this.otpForm.valid) {
      this.router.navigate(['/admin-dashboard']);
    }
  }
}
